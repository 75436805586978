import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Alert from "@material-ui/lab/Alert";
import _map from "lodash-es/map";
import _replace from "lodash-es/replace";
import _toLower from "lodash-es/toLower";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ArticleDialog from "./ArticleDialog";
import HomeSidebar from "./HomeSidebar";
import {
  FETCH_ARTICLES_SUCCESS,
  OPEN_DIALOG,
  REDIRECT_ARTICLE,
} from "./actionTypes";
import { fetchArticles } from "./api";

const useStyles = makeStyles((theme) => ({
  nestList: {
    counterReset: "item",
    display: "inline-block",
  },
  nestListItem: {
    "alignItems": "baseline",
    "&::before": {
      color: theme.palette.primary.main,
      counterIncrement: "item",
      display: "inline-block",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      marginLeft: "-1em",
      width: "1.5em",
    },
  },
  main: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },

  link: {
    "fontSize": "16px",
    "textAlign": "left",
    "fontFamily": theme.typography.fontFamily,
    "background": "#f2f2f2",
    "width": "100%",
    "paddingLeft": "15px",
    "padding": "8px",
    "transition": "background 0.7s ease",
    "borderRadius": "5px",
    "&:hover": {
      background: "#000066",
      color: "white",
      fontWeight: 600,
      textDecoration: "none",
    },
  },
}));

const IndiaHome = () => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { push } = useHistory();

  const dispatch = useDispatch();
  const action = useSelector((state) => state.action);
  const article = useSelector((state) => state.article);
  const articles = useSelector((state) => state.articles);
  const nextAction = useSelector((state) => state.next);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const SidebarNestedListItem = withStyles({
    root: {
      "&::before": {
        content: `counter(item, upper-alpha) '.'`,
      },
    },
  })(ListItem);
  const SidebarNestedInnerListItem = withStyles({
    root: {
      "&::before": {
        content: `counter(item, lower-roman) '.'`,
      },
    },
  })(ListItem);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({
          type: FETCH_ARTICLES_SUCCESS,
          articles: await fetchArticles(),
        });
      } catch ({ message }) {
        setError(message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    switch (action) {
      case REDIRECT_ARTICLE:
        push({
          pathname: `/${_replace(
            _toLower(article.attributes.title),
            /\s/g,
            "-"
          )}`,
        });
        break;
      case OPEN_DIALOG:
        dispatch({ type: nextAction });
        break;
      default:
        break;
    }
  }, [action, article, dispatch, nextAction, push]);

  const renderArticles = () =>
    _map(articles, (article) => {
      // console.log(article);
      const { activateFrom, activateTo, id } = article;
      return moment().isBetween(activateFrom, activateTo, undefined, "[]") ? (
        <Typography paragraph color="primary" key={article.id} variant="body2">
          <Link
            component="button"
            href={`?t=${id}`}
            className={classes.link}
            onClick={() => {
              dispatch({ type: OPEN_DIALOG, article });
            }}
          >
            {article.attributes.title}
          </Link>
        </Typography>
      ) : null;
    });

  const renderError = () => <Alert severity="error">{error}</Alert>;

  return (
    <>
      {isLoading ? <LinearProgress /> : null}
      <Grid
        spacing={10}
        container
        direction={fullScreen ? "column-reverse" : "row"}
      >
        <Grid item md={4} xs={12}>
          {fullScreen ? <Divider /> : null}

          <Box
            m={fullScreen ? theme.spacing(2, 0) : 0}
            textAlign={fullScreen ? "center" : "left"}
          >
            <HomeSidebar />
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <Box>
            <Typography color="primary" component="h2">
              MERCHANT BANKING SECTION
            </Typography>
            <Box
              borderColor="primary.main"
              borderTop={1}
              display="inline-block"
            >
              <Typography color="primary" varianta="subtitle1">
                SEBI Registration No: INM000010619
              </Typography>
            </Box>
            <Box sx={{ fontWeight: 500 }}>
              <Typography
                color="primary"
                component="h1"
                style={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  fontWeight: "600",
                }}
              >
                Click below for the Offer Document:
              </Typography>
            </Box>
            {error ? renderError() : renderArticles()}
          </Box>
        </Grid>
      </Grid>
      <ArticleDialog />
    </>
  );
};

export default IndiaHome;
