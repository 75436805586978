import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 0),
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <img
        alt=""
        border="0"
        height={2}
        src="data:image/gif;base64,R0lGODlh+wICANUAAAsLbZycxFJSmNbW5yoqgHt7r7291+/v9kJCjR0dd2trpoqKuK2tzs3N4RgYdN/f7Pj4+0pKkzo6iGZmmXR0rJSUvhAQb7S00cXF3YSEtaWlyObm8FJSlygofvb2+TExhNzc6lpanBERcbW1039/so2Nu2Njojs7is/P4////6amyr293pycvWtrrUJClCEhepmZzAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAAHAP8ALAAAAAD7AgIAAAb/wJRwCCkaIZ5k8sBsOpmbqHS6eVivD5B2O+h6v6hwuEEulzHotNrAbrvZo7j8Qq8z7vi8ar/X+P8aAYKDhIMVh4iJJYuMJQuPkAsZk5QZJJeYJAWbnAUUn6ChnwqkpaalJqmqqyYhrq+wIQKztBy2thG5ursRCL6/wAgnw8QSxscSH8rLzB8Ez9AEHdPUL9bX1gna2w7d3t0i4eIW5BYA5+jp6uvs7eXv5eLi3/QO2/fY+dTU0dHN/8qQISNGLJhBX7wS5rpli5ZDAbEihmBFMdWpi6VEafzUqVOmTJVCRoLUqFGik4cKqSQECBAfPnli3qlDU46cNzjbqNmJxozP1QZixHwZ2mWLUSxYqCjd8KTpASVQjxgZQlWI1CJQPTh9snQKUitGtRAFE/TnGZ5pcr6xGYcmHZl6Xrb0s1IlSkUlHY2UFNLSR00dPW0MhfFixVUSYT0UwJCDwl0HgREcJtAYQGb9nu2blu/aPW31wMkT8a6d6dOm4cEbLSK0t8/aOl/b3CHzs8vNKhubfCJysMe8Gi+elRjWYVaFLw4WFXjT30t9Ke1dkHfR3UR1V87183IP3Dxu6bAdoRYnWjVmywQNM/ZLWC1frXSdstVJ1iRXIQwJAgA7"
      />
      <footer className={classes.footer}>
        <Container>
          <Typography color="textSecondary" variant="body2">
            <Link
              href="https://www.clsa.com/terms-and-conditions-of-use"
              rel="noreferrer"
              target="_blank"
            >
              Terms and conditions of use
            </Link>

            <br />
            {`Copyright `}
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />
            {` ${new Date().getFullYear()}`}
            {" CLSA India Private Limited. All rights reserved."}
          </Typography>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
