import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  nestList: {
    counterReset: "item",
    display: "inline-block",
  },
  nestListItem: {
    "alignItems": "baseline",
    "&::before": {
      color: theme.palette.primary.main,
      counterIncrement: "item",
      display: "inline-block",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      marginLeft: "-1em",
      width: "1.5em",
    },
  },
  main: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));

const Notification = () => {
  const classes = useStyles();

  const SidebarNestedListItem = withStyles({
    root: {
      "&::before": {
        content: `counter(item, upper-alpha) '.'`,
      },
    },
  })(ListItem);

  const SidebarNestedInnerListItem = withStyles({
    root: {
      "&::before": {
        content: `counter(item, lower-roman) '.'`,
      },
    },
  })(ListItem);
  return (
    <Box>
      <Typography color="primary" variant="subtitle2">
        <Box fontWeight="fontWeightBold">
          Notification on segregation and monitoring of collateral at client
          level
        </Box>
      </Typography>

      <Typography paragraph color="primary" variant="subtitle2">
        Clearing corporation vide circular NCL/CMPL/49764 issued on September
        29, 2021 and NCL/CMPT/51868 issued on April 01, 2022 has put in place a
        framework for Segregation and Monitoring of Collateral at Client Level.
      </Typography>

      <Typography paragraph color="primary" variant="subtitle2">
        In accordance with aforesaid circular and with a view to provide
        visibility of client-wise collateral, Clearing Corporations have
        provided a web portal facility to allow investors to view their
        disaggregated collateral placed with Member and as reported by their
        registered Trading Member /Clearing Member.
      </Typography>

      <Typography color="primary" variant="subtitle2">
        <Box fontWeight="fontWeightBold">
          The said facility will provide the following benefits:
        </Box>
      </Typography>
      <List dense disablePadding className={classes.nestList} component="ul">
        <SidebarNestedListItem className={classes.nestListItem}>
          <Typography paragraph color="primary" variant="subtitle2">
            Investors can verify/ validate the collaterals deposited by them
            with the members vis-à-vis the collaterals reported by members to
            clearing corporations.
          </Typography>
        </SidebarNestedListItem>
        <SidebarNestedListItem className={classes.nestListItem}>
          <Typography paragraph color="primary" variant="subtitle2">
            Investors can also view break-up of their collateral lying with the
            trading member, clearing member and clearing corporations.
          </Typography>
        </SidebarNestedListItem>
        <SidebarNestedListItem className={classes.nestListItem}>
          <Typography paragraph color="primary" variant="subtitle2">
            Visibility through web portal will bring transparency with respect
            to their collateral placed with trading member on daily basis in
            seamless manner.
          </Typography>
        </SidebarNestedListItem>
      </List>

      <Typography paragraph color="primary" variant="subtitle2">
        Investors are urged to regularly view the details of their collateral
        available with the respective clearing corporations and in case
        Investors find any discrepancy in the collateral deposited by them with
        their members and as reported by respective trading members/clearing
        members to clearing corporations, they are advised to take up the matter
        immediately with their trading member. In case of non-resolution of
        discrepancy by the trading member, Investors may intimate the same to
        the Exchange at email id{" "}
        <Link underline="always" href="mailto:ignse@nse.co.in/toll">
          ignse@nse.co.in/<span></span>{" "}
        </Link>
        toll free number 18002660050 (Option 5) or register an online complaint
        at <span></span>
        <Link
          underline="always"
          href="https://www.nseindia.com/invest/download-complaint-form-for-offline-registration"
        >
          https://www.nseindia.com/invest/download-complaint-form-for-offline-registration
        </Link>
      </Typography>

      <Typography paragraph color="primary" variant="subtitle2">
        Procedure to view Collateral data by clients on the exchange website is
        enclosed.
      </Typography>

      <Typography paragraph color="primary" variant="subtitle2">
        The provisions of the circular shall be implemented w.e.f. May 02, 2022.
      </Typography>

      <Typography color="primary" variant="subtitle2">
        <Box fontWeight="fontWeightBold">Attached</Box>
      </Typography>
      <List dense disablePadding className={classes.nestList} component="ul">
        <SidebarNestedListItem className={classes.nestListItem}>
          <Typography paragraph color="primary" variant="subtitle2">
            <Link
              underline="always"
              href="/uploads/CMPL_49764_7446ad71fa.pdf?updated_at=2023-09-07T08:50:15.827Z"
            >
              Circular NCL/CMPL/49764{" "}
            </Link>{" "}
            issued on September 29, 2021 and <span></span>
            <Link
              underline="always"
              href="/uploads/CMPT_51868_e02d069374.pdf?updated_at=2023-09-07T08:50:15.894Z"
            >
              NCL/CMPT/51868
            </Link>{" "}
            issued on April 01, 2022
          </Typography>
        </SidebarNestedListItem>
        <SidebarNestedListItem className={classes.nestListItem}>
          <Typography paragraph color="primary" variant="subtitle2">
            <Link
              underline="always"
              href="/uploads/Procedure_to_view_collateral_data_by_clients_75df585291.pdf?updated_at=2023-09-07T08:50:15.895Z"
            >
              Procedure to view Collateral data by clients.
            </Link>
          </Typography>
        </SidebarNestedListItem>
      </List>
    </Box>
  );
};

export default Notification;
