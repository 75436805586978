import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _map from "lodash-es/map";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_COUNTRIES_SUCCESS } from "./actionTypes";
import { fetchCountries } from "./api";

const defaultSelectCountryMessage =
  window.defaultSelectCountryMessage ||
  process.env.REACT_APP_SELECT_COUNTRY_MESSAGE ||
  "";

const useStyles = makeStyles((theme) => ({
  countrySelect: {
    flexDirection: "column-reverse",
  },
  countrySelectHelperText: {
    color: theme.palette.text,
    fontSize: theme.typography.fontSize,
  },
}));

const CountrySelect = (props) => {
  const {
    helperText,
    hideHelperText = false,
    onSuccess = () => {},
    onFail = () => {},
    ...textFieldsProps
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const article = useSelector((state) => state.article);
  const countries = useSelector((state) => state.countries);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({
          type: FETCH_COUNTRIES_SUCCESS,
          countries: await fetchCountries(),
        });
      } catch ({ message }) {
        setError(message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  return (
    <TextField
      required
      select
      FormHelperTextProps={{
        classes: {
          root: classes.countrySelectHelperText,
        },
        required: true,
      }}
      SelectProps={{ autoWidth: true }}
      className={classes.countrySelect}
      defaultValue="placeholder"
      disabled={isLoading}
      error={!!error}
      fullWidth={!!fullScreen}
      helperText={
        error ||
        (hideHelperText ? null : helperText || defaultSelectCountryMessage)
      }
      name="country"
      onChange={({ target: { value: country } }) => {
        if (country === "IN") {
          // if (
          //   _isEmpty(article.attributes.authCountries) ||
          //   _includes(article.attributes.authCountries, country)) {
          onSuccess(country);
        } else {
          onFail(country);
        }
      }}
      {...textFieldsProps}
    >
      {
        <MenuItem disabled value="placeholder">
          Select Country
        </MenuItem>
      }
      {_map(countries, ({ countryCode: code, countryName: name }) => (
        <MenuItem key={code} value={code}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CountrySelect;
