import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_ADVISORY_INVESTORS_SUCCESS } from "./actionTypes";
import { fetchRiskDisclosures } from "./api";

const useStyles = makeStyles((theme) => ({
  nestList: {
    counterReset: "item",
    display: "inline-block",
  },
  nestListItem: {
    "alignItems": "baseline",
    "&::before": {
      color: theme.palette.primary.main,
      counterIncrement: "item",
      display: "inline-block",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      marginLeft: "-1em",
      width: "1.5em",
    },
  },
  main: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));
const RiskDisclosures = () => {
  const { tests } = useSelector((state) => state.article);
  const trackRecords = useSelector((state) => state.trackRecords);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const advisoryForInvestors = useSelector(
    (state) => state.advisoryForInvestors
  );

  // FOR FEELING OF Advisory For Investors //
  useEffect(() => {
    fetchRiskDisclosures();
    const fetchAdvisory = async () => {
      try {
        dispatch({
          type: FETCH_ADVISORY_INVESTORS_SUCCESS,
          advisoryForInvestors: await fetchRiskDisclosures(),
        });
      } catch ({ message }) {
        setError(message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAdvisory();
  }, [dispatch]);

  return (
    <>
      {isLoading ? <LinearProgress /> : null}
      <Grid
        spacing={10}
        container
        direction={fullScreen ? "column-reverse" : "row"}
      >
        <Grid>
          <Box
            m={fullScreen ? theme.spacing(2, 0) : 0}
            textAlign={fullScreen ? "center" : "left"}
          ></Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box>
            <Typography style={{ display: "flex", justifyContent: "center" }}>
              <Box fontWeight="fontWeightBold">
                !! FOR ATTENTION OF INVESTORS !!
              </Box>
            </Typography>

            {advisoryForInvestors?.map((data) => (
              <paragraph
                color="primary"
                variant="subtitle2"
                dangerouslySetInnerHTML={{
                  __html: `
              ${data?.attributes?.text}`,
                }}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RiskDisclosures;
