import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoSideBar from "./InfoSideBar";
import { FETCH_ADVISORY_INVESTORS_SUCCESS } from "./actionTypes";
import { fetchAdvisoryForInvestors } from "./api";

const useStyles = makeStyles((theme) => ({
  nestList: {
    counterReset: "item",
    display: "inline-block",
  },
  nestListItem: {
    "alignItems": "baseline",
    "&::before": {
      color: theme.palette.primary.main,
      counterIncrement: "item",
      display: "inline-block",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      marginLeft: "-1em",
      width: "1.5em",
    },
  },
  main: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));
const CautionForInvestor = () => {
  const { tests } = useSelector((state) => state.article);
  const trackRecords = useSelector((state) => state.trackRecords);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const advisoryForInvestors = useSelector(
    (state) => state.advisoryForInvestors
  );

  // FOR FEELING OF Advisory For Investors //
  useEffect(() => {
    fetchAdvisoryForInvestors();
    const fetchAdvisory = async () => {
      try {
        dispatch({
          type: FETCH_ADVISORY_INVESTORS_SUCCESS,
          advisoryForInvestors: await fetchAdvisoryForInvestors(),
        });
      } catch ({ message }) {
        setError(message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAdvisory();
  }, [dispatch]);

  return (
    <>
      {isLoading ? <LinearProgress /> : null}
      <Grid
        spacing={10}
        container
        direction={fullScreen ? "column-reverse" : "row"}
      >
        <Grid item md={4} xs={12}>
          <Box
            m={fullScreen ? theme.spacing(2, 0) : 0}
            textAlign={fullScreen ? "center" : "left"}
          >
            <InfoSideBar />
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <Box>
            <Typography style={{ display: "flex", justifyContent: "center" }}>
              <Box fontWeight="fontWeightBold">!!Caution for Investors!!</Box>
            </Typography>

            <Typography style={{ margin: "20px 0px" }}>
              Investors are cautioned and advised not share their trading
              account credentials and or subscribe to any such scheme/ product
              offered by any person/ entity offering indicative/ assured/
              guaranteed returns in the stock market as the same is prohibited
              under law.
            </Typography>

            <Typography style={{ display: "flex", justifyContent: "center" }}>
              <Box fontWeight="fontWeightBold">
                {" "}
                Please refer to the below links:{" "}
              </Box>
            </Typography>

            {advisoryForInvestors?.map((data) => (
              <Typography paragraph color="primary" variant="subtitle2">
                <Link underline="always" href={`${data?.attributes?.url}`}>
                  {data?.attributes?.title}
                </Link>
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CautionForInvestor;
