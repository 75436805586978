import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";

const InvestorsAwareness = () => {
  return (
    <div>
      <Typography
        color="primary"
        variant="subtitle2"
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box fontWeight="fontWeightBold">
          !!For Attention of Clients/Investors!!
        </Box>
      </Typography>
      <ul>
        <li style={{ marginBottom: "20px" }}>
          The Securities and Exchange Board of India ('SEBI') requires
          intermediaries registered with it to adhere to the client account
          opening procedures, maintenance of records and reporting of
          transactions, as prescribed by SEBI and under the Prevention of Money
          Laundering Act, 2002 ('PMLA') and rules notified there under from time
          to time.
        </li>
        <li style={{ marginBottom: "20px" }}>
          In order to comply with SEBI regulations and PMLA requirements, CLSA
          India requests for certain information and documents from investors,
          including information of personal nature like evidence of source of
          funds/ income tax returns/ bank records, proofs of identity and
          address, etc.
        </li>
        <li style={{ marginBottom: "20px" }}>
          SEBI has also directed intermediaries that, in cases where there is a
          variance in Client Due Diligence ('CDD')/ Anti Money Laundering
          standards specified by SEBI and regulators of the investor's host
          country, registered intermediaries are required to adopt the more
          stringent requirements of the two. Further, registered intermediaries
          are also required to periodically update all documents, data or
          information of all clients and beneficial owners collected under the
          CDD process.
        </li>
        <li style={{ marginBottom: "20px" }}>
          SEBI also prescribes that intermediaries shall not allow the opening
          of or keeping any anonymous account or account in fictitious names or
          account on behalf of other persons whose identity has not been
          disclosed or cannot be verified.
        </li>
        <li style={{ marginBottom: "20px" }}>
          SEBI mandates that the intermediary shall also ensure that an account
          is not opened where the intermediary is unable to apply appropriate
          CDD measures. This shall apply in cases where it is not possible to
          ascertain the identity of the client, or the information provided to
          the intermediary is suspected to be non - genuine, or there is
          perceived non - co-operation of the client in providing full and
          complete information. As per SEBI regulations the intermediary shall
          not continue to do business with such a person and file a suspicious
          activity report.
        </li>
      </ul>
    </div>
  );
};

export default InvestorsAwareness;
