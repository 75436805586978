/**
 * AcceptForm is a functional component that renders a form with various form controls based on the formControls prop. It manages the state of the form controls and handles form submission.
 *
 * @component
 * @example
 * <AcceptForm
 *   formControls={["declare-of-country", "declare-resident-of-country"]}
 *   onClose={() => {}}
 *   onSubmit={(country) => {}}
 * />
 *
 * @param {Object} props - The component props.
 * @param {Array} props.formControls - An array of strings representing the form controls to be rendered.
 * @param {Function} props.onClose - A function to be called when the form is closed.
 * @param {Function} props.onSubmit - A function to be called when the form is submitted. It receives the selected country as a parameter.
 * @returns {JSX.Element} The rendered form component.
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import _size from "lodash-es/size";
import React, { useRef, useState } from "react";
import CountrySelect from "./CountrySelect";

const AcceptForm = (props) => {
  const { formControls = [], onClose = () => {}, onSubmit = () => {} } = props;
  const formControlsSet = new Set(formControls);
  const [country1, setCountry1] = useState("");

  const [country, setCountry] = useState("");
  const [isRead, setIsRead] = useState(false);
  const [isNonUsOrQp, setIsNonUsOrQp] = useState(false);

  const validFormControlerCount = useRef(0);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(country);
      }}
    >
      <FormGroup>
        {formControlsSet.has("declare-of-country") ? (
          <Box id="declare-of-country" lineHeight={2}>
            <Typography paragraph component="span" variant="body2">
              I here by confirm that, I am a resident of,
            </Typography>
            {(() => {
              validFormControlerCount.current += country ? 1 : -1;
            })()}
            {/* <CountrySelect hideHelperText /> */}
            <Box component="span" mx="2em">
              <CountrySelect hideHelperText />
            </Box>
          </Box>
        ) : null}
        {formControlsSet.has("declare-resident-of-country") ? (
          <Box id="declare-resident-of-country" lineHeight={2}>
            <Typography paragraph component="span" variant="body2">
              I hereby declare that I am a resident of
            </Typography>
            <Box component="span" mx="2em">
              <CountrySelect
                hideHelperText
                value={country || "placeholder"}
                onChange={({ target: { value: country } }) => {
                  setCountry(country);
                  validFormControlerCount.current += country ? 1 : -1;
                }}
              />
            </Box>
            <Typography paragraph component="span" variant="body2">
              and there are no restrictions on my accessing or receiving the
              information contained on this website.
            </Typography>
          </Box>
        ) : null}

        {formControlsSet.has("delcare-not-us-resident") ? (
          <Box id="delcare-not-us-resident">
            <Typography component="span" variant="body2">
              I hereby declare I am not a resident of the United States and am
              entitled to receive information contained on this website.
            </Typography>
            {(() => {
              validFormControlerCount.current += country ? 1 : -1;
            })()}
          </Box>
        ) : null}
        {formControlsSet.has("read-legal-disclaimer") ? (
          <Box id="read-legal-disclaimer">
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Checkbox
                  required
                  color="primary"
                  name="read"
                  value={isRead}
                  onChange={({ target: { checked } }) => {
                    setIsRead(checked);
                    validFormControlerCount.current += checked ? 1 : -1;
                  }}
                />
              }
              label={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography variant="body2">
                  I have read the Legal Disclaimer and am entitled to receive
                  information contained in this web site.
                </Typography>
              }
            />
          </Box>
        ) : null}
        {formControlsSet.has("non-us-or-qp") ? (
          <Box id="non-us-or-qp">
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Checkbox
                  required
                  color="primary"
                  name="read"
                  value={isNonUsOrQp}
                  onChange={({ target: { checked } }) => {
                    setIsNonUsOrQp(checked);
                    validFormControlerCount.current += checked ? 1 : -1;
                  }}
                />
              }
              label={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography variant="body2">
                  Either (i) I am non-U.S. person (as defined in Regulations
                  under the U.S. Securities Act of 1933, as amended) outside the
                  United States or (ii) I am an Entitled QP (as defined above)
                </Typography>
              }
            />
          </Box>
        ) : null}
        {formControlsSet.has("not-us-person-not-in-us") ? (
          <Box id="not-us-person-not-in-us">
            <Typography paragraph variant="body2">
              If you are not a U.S. Person, are not in the ed States and are
              entitled to receive information contained on this ite under the
              laws of the jurisdictions in which you are in and resident, please
              click [
              <Link component="button" href="#" underline="always">
                here
              </Link>
              ]
            </Typography>
            {(() => {
              validFormControlerCount.current += 1;
            })()}
          </Box>
        ) : null}
        {formControlsSet.has("not-us-person-in-us") ? (
          <Box id="not-us-person-in-us">
            <Typography paragraph variant="body2">
              If you are not a U.S. person or in the United States, please click
              <Link component="button" href="#" underline="always">
                here
              </Link>
              ]
            </Typography>
            {(() => {
              validFormControlerCount.current += 1;
            })()}
          </Box>
        ) : null}
      </FormGroup>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          disabled={_size(formControls) !== validFormControlerCount.current}
          type="submit"
        >
          I Accept
        </Button>
        <Button
          color="primary"
          onClick={() => {
            validFormControlerCount.current = 0;
            onClose();
          }}
        >
          I Do Not Accept
        </Button>
      </Box>
    </form>
  );
};

export default AcceptForm;
