export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_TRACKRECORDS_SUCCESS = "FETCH_TRACKRECORDS_SUCCESS";
export const FETCH_ACCOUNTOPENINGFROM_SUCCESS =
  "FETCH_ACCOUNTOPENINGFROM_SUCCESS";
export const FETCH_INVESTOR_SUCCESS = "FETCH_INVESTOR_SUCCESS";
export const FETCH_STOCK_SUCCESS = "FETCH_STOCK_SUCCESS";
export const FETCH_RESEARCH_SUCCESS = "FETCH_RESEARCH_SUCCESS";
export const FETCH_ADVISORY_INVESTORS_SUCCESS =
  "FETCH_ADVISORY_INVESTORS_SUCCESS";
export const FETCH_FEELING_SUCCESS = "FETCH_FEELING_SUCCESS";
export const FETCH_STOCK_BROKING_E_VOTING_SUCCESS =
  "FETCH_STOCK_BROKING_E_VOTING_SUCCESS";
export const FETCH_GENERAL_INFOMATIONS_SUCCESS =
  "FETCH_GENERAL_INFOMATIONS_SUCCESS";
export const FETCH_CONTACT_INFOS_SUCCESS = "FETCH_CONTACT_INFOS_SUCCESS";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const OPEN_DIALOG = "OPEN_DIALOG";
export const REDIRECT_ARTICLE = "REDIRECT_ARTICLE";
export const RENDER_ACCEPT_DIALOG = "RENDER_ACCEPT_DIALOG";
export const RENDER_CERTIFY_DIALOG = "RENDER_CERTIFY_DIALOG";
export const RENDER_CONFIRM_DIALOG = "RENDER_CONFIRM_DIALOG";
export const RENDER_ERROR_DIALOG = "RENDER_ERROR_DIALOG";
export const RENDER_FORM_DIALOG = "RENDER_FORM_DIALOG";
export const RENDER_SELECT_COUNTRY_DIALOG = "RENDER_SELECT_COUNTRY_DIALOG";
export const RENDER_SELECT_RESIDE_AND_COUNTRY_DIALOG =
  "RENDER_SELECT_RESIDE_AND_COUNTRY_DIALOG";
