import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import _head from "lodash-es/head";
import _isArray from "lodash-es/isArray";
import _isEmpty from "lodash-es/isEmpty";
import _map from "lodash-es/map";
import _size from "lodash-es/size";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AcceptForm from "./AcceptForm";
import AccessForm from "./AccessForm";
import CountrySelect from "./CountrySelect";
import {
  CLOSE_DIALOG,
  RENDER_ACCEPT_DIALOG,
  RENDER_CERTIFY_DIALOG,
  RENDER_CONFIRM_DIALOG,
  RENDER_ERROR_DIALOG,
  RENDER_FORM_DIALOG,
  RENDER_SELECT_COUNTRY_DIALOG,
  RENDER_SELECT_RESIDE_AND_COUNTRY_DIALOG,
} from "./actionTypes";

const defaultConfirmMessage =
  window.defaultConfirmMessage ||
  process.env.REACT_APP_RENDER_CONFIRM_DIALOG_MESSAGE ||
  "";
const defaultErrorMessage =
  window.defaultErrorMessage ||
  process.env.REACT_APP_RENDER_ERROR_DIALOG_MESSAGE ||
  "";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
  },
  pageniation: {
    justifyContent: "center",
  },
  continueButton: {
    "background": "#f2f2f2",
    "margin": "12px",
    "padding": "5px 30px",
    "&:hover": {
      background: "#000066",
      color: "white",
      fontWeight: 600,
      textDecoration: "none",
    },
  },
}));

const ArticleDialog = () => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const action = useSelector((state) => state.action);
  const article = useSelector((state) => state.article);
  const nextAction = useSelector((state) => state.next);

  const history = useHistory();

  const [content, setContent] = useState([]);
  const [dialogActions, setDialogActions] = useState();
  const [page, setPage] = useState(0);

  const contentLength = _isArray(content) ? _size(content) : 1;

  const handleDialogClose = useCallback(() => {
    setPage(0);
    dispatch({ type: CLOSE_DIALOG });
  }, [dispatch]);

  useEffect(() => {
    switch (action) {
      case RENDER_ACCEPT_DIALOG:
        setContent(article.attributes.terms);
        if (article.attributes.threestep === true) {
          setDialogActions(
            <Button
              color="primary"
              onClick={() => {
                setPage(0);
                dispatch({ type: RENDER_CONFIRM_DIALOG });
              }}
              className={classes.continueButton}
            >
              Continue
            </Button>
          );
        } else {
          if (article.attributes.usstep === true) {
            setDialogActions(
              <Box flexDirection="column" style={{ padding: "0px 30px" }}>
                <AcceptForm
                  formControls={[
                    ...(article.formControls || []),
                    "declare-of-country",
                    "declare-resident-of-country",
                    "read-legal-disclaimer",
                    "delcare-not-us-resident",
                  ]}
                  onClose={handleDialogClose}
                  onSubmit={(country) => {
                    if (country === "IN") {
                      history.push(`/${article?.attributes.title}`);

                      // if (_includes(article.attributes.authCountries, country)) {
                      //   setPage(0);
                      //   dispatch({ type: nextAction });
                    } else {
                      dispatch({
                        type: RENDER_ERROR_DIALOG,
                        next: RENDER_ACCEPT_DIALOG,
                      });
                    }
                  }}
                />
              </Box>
            );
          } else {
            setDialogActions(
              <Box flexDirection="column" style={{ padding: "0px 30px" }}>
                <AcceptForm
                  formControls={[
                    ...(article.formControls || []),
                    "declare-resident-of-country",
                    "read-legal-disclaimer",
                  ]}
                  onClose={handleDialogClose}
                  onSubmit={(country) => {
                    if (country === "IN") {
                      history.push(`/${article?.attributes.title}`);

                      // if (_includes(article.attributes.authCountries, country)) {
                      //   setPage(0);
                      //   dispatch({ type: nextAction });
                    } else {
                      dispatch({
                        type: RENDER_ERROR_DIALOG,
                        next: RENDER_ACCEPT_DIALOG,
                      });
                    }
                  }}
                />
              </Box>
            );
          }
        }
        break;
      case RENDER_CERTIFY_DIALOG:
        setContent(article.attributes.terms);
        setDialogActions(
          <Button
            color="primary"
            onClick={() => {
              setPage(-1);
              dispatch({ type: nextAction });
            }}
          >
            I certify that I am authorized to access this site
          </Button>
        );
        break;
      case RENDER_CONFIRM_DIALOG:
        setContent([defaultConfirmMessage]);
        setDialogActions(
          <>
            <Button
              color="primary"
              onClick={() => {
                setPage(0);
                dispatch({ type: nextAction });
              }}
            >
              I Confirm
            </Button>
            <Button color="primary" onClick={handleDialogClose}>
              I Do Not Confirm
            </Button>
          </>
        );
        break;
      case RENDER_ERROR_DIALOG:
        setContent(article.error || defaultErrorMessage);
        setDialogActions(
          <Box mr="auto" style={{ padding: " 10px 30px" }}>
            {/* <Typography color="primary" variant="subtitle2">
              Access Denied Based on restrictions imposed by law on soliciting
              securities business in various jurisdictions, the information
              contained in this site is not directed at persons who are not
              residents of India. The information in this website is directed
              at, and is, intended for distribution to, and use by, residents of
              India only.
            </Typography>
            <Typography
              color="primary"
              variant="subtitle2"
              style={{ marginTop: "10px" }}
            >
              The information contained in this website does not constitute an
              offer for sale in the United States. The securities described in
              each of the Offering Documents have not been, and will not be,
              registered under U.S. Securities Act of 1933, as amended, or with
              any securities regulatory authority of any state or other
              jurisdiction in the United States and may not be offered or sold,
              directly or indirectly, into the United States unless the
              securities are so registered or an exemption from the registration
              requirements is available.
            </Typography> */}
            <Button
              color="primary"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => {
                setPage(0);
                dispatch({ type: nextAction });
              }}
            >
              Back
            </Button>
          </Box>
        );
        break;
      case RENDER_FORM_DIALOG:
        setContent(_head(article.messages));
        setDialogActions(
          <Grid container justify="center">
            <AccessForm
              onClose={handleDialogClose}
              onFail={() => {
                setPage(0);
                dispatch({
                  type: RENDER_ERROR_DIALOG,
                  next: RENDER_FORM_DIALOG,
                });
              }}
              onSuccess={() => {
                setPage(0);
                dispatch({ type: nextAction });
              }}
            />
          </Grid>
        );
        break;
      case RENDER_SELECT_COUNTRY_DIALOG:
        setContent();
        setDialogActions(
          <CountrySelect
            helperText={_head(article.messages)}
            onFail={() => {
              setPage(0);
              dispatch({
                type: RENDER_ERROR_DIALOG,
                next: RENDER_SELECT_COUNTRY_DIALOG,
              });
            }}
            onSuccess={() => {
              setPage(0);
              dispatch({ type: nextAction });
            }}
          />
        );
        break;
      case RENDER_SELECT_RESIDE_AND_COUNTRY_DIALOG:
        setContent();
        setPage(0);
        setDialogActions(
          <Grid container>
            {_map(article.messages, (message, key) => (
              <Grid item key={key} md="auto" xs={12}>
                <CountrySelect
                  helperText={message}
                  onFail={() => {
                    setPage(0);
                    dispatch({
                      type: RENDER_ERROR_DIALOG,
                      next: RENDER_SELECT_RESIDE_AND_COUNTRY_DIALOG,
                    });
                  }}
                  onSuccess={() => {
                    setPage(0);
                    dispatch({ type: nextAction });
                  }}
                />
              </Grid>
            ))}
          </Grid>
        );
        break;
      default:
        break;
    }
  }, [dispatch, article, theme, handleDialogClose, action, nextAction]);

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      id={`${article.id}-${article ? article?.attributes?.title : ""}-dialog`}
      maxWidth="sm"
      open={!_isEmpty(article)}
      onClose={handleDialogClose}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography component="span" variant="h6">
          {article?.attributes?.title}
        </Typography>
        {fullScreen ? (
          <IconButton color="inherit" edge="end" onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {_isEmpty(content) ? null : (
          <DialogContentText>
            {/* <Typography color="primary" component="span"> */}
            {action === RENDER_ERROR_DIALOG ? (
              <Alert severity="error">
                <Typography
                  color="primary"
                  component="span"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: _isArray(content)
                      ? content[page < 0 ? contentLength + page : page]
                      : content,
                  }}
                />
              </Alert>
            ) : (
              <Typography
                color="primary"
                component="span"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: _isArray(content)
                    ? content[page < 0 ? contentLength + page : page]
                    : content,
                }}
              />
            )}
            {/* </Typography> */}
          </DialogContentText>
        )}
        {contentLength > 1 ? (
          <Pagination
            classes={{ ul: classes.pageniation }}
            count={contentLength}
            page={(page < 0 ? contentLength + page : page) + 1}
            onChange={(e, page) => {
              setPage(page - 1);
            }}
          />
        ) : null}
      </DialogContent>
      {page === -1 || page === contentLength - 1 ? (
        <DialogActions>{dialogActions}</DialogActions>
      ) : null}
    </Dialog>
  );
};

export default ArticleDialog;
