import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import AccountOpeningForm from "./AccountOpeningForm";
import CautionForInvestor from "./CautionForInvestor";
import ClientBankAccounts from "./ClientBankAccounts";
import Footer from "./Footer";
import Header from "./Header";
import IndiaDocuments from "./IndiaDocuments";
import IndiaHome from "./IndiaHome";
import InvestorCharter from "./InvestorCharter";
import InvestorsAwareness from "./InvestorsAwareness";
import Notification from "./Notification";
import RiskDisclosures from "./RiskDisclosures";
import TrackRecord from "./TrackRecord";
import theme from "./theme";

const App = () => {
  // const article = useSelector((state) => state.article);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="container">
          <Header />
          <Switch>
            <Route exact path="/">
              <IndiaHome />
            </Route>
            <Route path="/trackrecord">
              <TrackRecord />
            </Route>
            <Route path="/notification">
              <Notification />
            </Route>
            <Route path="/investor_charter">
              <InvestorCharter />
            </Route>
            <Route path="/investors_awareness">
              <InvestorsAwareness />
            </Route>
            <Route path="/client_bank_accounts">
              <ClientBankAccounts />
            </Route>
            <Route path="/caution_for_investor">
              <CautionForInvestor />
            </Route>

            <Route path="/risk_disclosures">
              <RiskDisclosures />
            </Route>
            <Route path="/account_opening_form">
              <AccountOpeningForm />
            </Route>
            <Route exact path="/:title">
              <IndiaDocuments />
            </Route>
            {/* <Route path="/:title">
            {_isEmpty(article) ? <Redirect to="/" /> : <TrackRecord />}
          </Route> */}
          </Switch>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
