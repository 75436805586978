/** @format */

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoSideBar from "./InfoSideBar";
import { FETCH_TRACKRECORDS_SUCCESS } from "./actionTypes";
import { fetchTrackRecords } from "./api";

const documentHost =
  window.documentHost || process.env.REACT_APP_DOCUMENTS_HOST || "";

const useStyles = makeStyles((theme) => ({
  nestList: {
    counterReset: "item",
    display: "inline-block",
  },
  nestListItem: {
    "alignItems": "baseline",
    "&::before": {
      color: theme.palette.primary.main,
      counterIncrement: "item",
      display: "inline-block",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      marginLeft: "-1em",
      width: "1.5em",
    },
  },
  main: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));
const TrackRecord = () => {
  const { tests } = useSelector((state) => state.article);
  const trackRecords = useSelector((state) => state.trackRecords);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchTrackRecords();
    const fetchTrack = async () => {
      try {
        dispatch({
          type: FETCH_TRACKRECORDS_SUCCESS,
          trackRecords: await fetchTrackRecords(),
        });
      } catch ({ message }) {
        setError(message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTrack();
  }, [dispatch]);

  return (
    <>
      {isLoading ? <LinearProgress /> : null}

      <Grid
        spacing={10}
        container
        direction={fullScreen ? "column-reverse" : "row"}
      >
        <Grid item md={4} xs={12}>
          <Box
            m={fullScreen ? theme.spacing(2, 0) : 0}
            textAlign={fullScreen ? "center" : "left"}
          >
            <InfoSideBar />
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <Box>
            <Typography
              style={{ borderBottom: "1px solid", width: "max-content" }}
            >
              MERCHANT BANKING SECTION
            </Typography>
            <Typography>SEBI Registration No: INM000010619</Typography>
            <Typography style={{ fontWeight: "600" }}>
              Track Record of Public Issues:
            </Typography>
            <br />
            {trackRecords?.data?.map((track) => (
              <Typography
                style={{ margin: "15px" }}
                paragraph
                color="primary"
                variant="subtitle2"
                key={track?.id}
              >
                <Link
                  href={`${documentHost}${track?.attributes?.url}`}
                  underline="always"
                >
                  {track?.attributes?.name}
                </Link>
              </Typography>
            ))}
            <Typography
              style={{
                margin: "15px",
                textAlign: "right",
                fontSize: "12px",
              }}
              paragraph
              color="primary"
              variant="subtitle2"
            >
              Information updated as of January 7, 2013 <br />
              The above information has been provided by CLSA India Private
              Limited <br />
              Solely pursuant to the provisions of SEBI Circular No.
              CIR/MIRSD/1/2012 dated January 10, 2012. reserved.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TrackRecord;
