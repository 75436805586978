import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_CONTACT_INFOS_SUCCESS } from "./actionTypes";
import { fetchInfos } from "./api";

const InfoSideBar = () => {
  const dispatch = useDispatch();
  const infos = useSelector((state) => state.infos);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // fetchInfos();

    const fetchContactInfos = async () => {
      try {
        dispatch({
          type: FETCH_CONTACT_INFOS_SUCCESS,
          infos: await fetchInfos(),
        });
      } catch ({ message }) {
        setError(message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchContactInfos();
  }, [dispatch]);
  return (
    <Box
      sx={{
        backgroundColor: "rgb(234 240 254 / 72%)",
        padding: 20,
        borderRadius: 6,
        color: "#000000",
      }}
    >
      <Typography color="primary" variant="subtitle2">
        <Box fontWeight="fontWeightBold">Registered Office</Box>
      </Typography>
      <Typography paragraph color="primary" variant="body2">
        8/F, Dalamal House
        <br />
        Nariman Point
        <br />
        Mumbai 400 021
        <br />
        Phone: +(91) 22 6650 5050
        <br />
        Fax: +(91) 22 2284 0271
        <br />
        Corporate Identification Number:
        <br />
        U67120MH1994PTC083118
      </Typography>

      {/* <Typography paragraph color="primary" variant="body2">
      Corporate Identification Number:
        <br />
        U67120MH1994PTC083118

      </Typography> */}

      <Typography color="primary" variant="subtitle2">
        <Box fontWeight="fontWeightBold">
          Email ID for redressal of investor complaints:
        </Box>
      </Typography>

      <Typography color="primary" variant="subtitle2">
        <Link href={`mailto:investor.helpdesk@clsa.com`} underline="always">
          investor.helpdesk@clsa.com
        </Link>
        <br />
        <br />
      </Typography>

      <Typography paragraph color="primary" variant="body2">
        For escalation you may contact
        <br />
        Ms. Neeta Sanghavi, Compliance Officer at:
        <br />
        Email Id: <span></span>
        <Link href={`mailto:Investor.Grievance@clsa.com`} underline="always">
          Investor.Grievance@clsa.com
        </Link>
        <br />
        Phone no.: 022 6650 5048
        <br />
      </Typography>

      {infos?.map((d) => (
        <>
          {/*   <Typography color="primary" variant="subtitle2">
            <Box fontWeight="fontWeightBold">Registered Office</Box>
          </Typography>
          <Typography
            paragraph
            color="primary"
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: `
              ${d?.attributes?.registeredOffice}<br />`,
            }}
          />*/}
          <Box fontWeight="fontWeightBold">
            <Link
              href={`${documentHost}${d?.attributes?.url}`}
              underline="always"
            >
              Contact us
            </Link>
          </Box>
        </>
      ))}
    </Box>
  );
};

export default InfoSideBar;
