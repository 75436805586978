import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoSideBar from "./InfoSideBar";
import ResearchSection from "./ResearchSection";
import StockBroking from "./StockBroking";
import { FETCH_INVESTOR_SUCCESS } from "./actionTypes";
import { fetchInvestorcharters } from "./api";

const documentHost =
  window.documentHost || process.env.REACT_APP_DOCUMENTS_HOST || "";

const useStyles = makeStyles((theme) => ({
  nestList: {
    counterReset: "item",
    display: "inline-block",
  },
  nestListItem: {
    "alignItems": "baseline",
    "&::before": {
      color: theme.palette.primary.main,
      counterIncrement: "item",
      display: "inline-block",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      marginLeft: "-1em",
      width: "1.5em",
    },
  },
  main: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));

const InvestorCharter = () => {
  const investor = useSelector((state) => state.investor);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchInvestorcharters();
    const fetchInvestor = async () => {
      try {
        dispatch({
          type: FETCH_INVESTOR_SUCCESS,
          investor: await fetchInvestorcharters(),
        });
      } catch ({ message }) {
        setError(message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchInvestor();
  }, [dispatch]);

  return (
    <>
      {isLoading ? <LinearProgress /> : null}
      <Grid
        spacing={10}
        container
        direction={fullScreen ? "column-reverse" : "row"}
      >
        <Grid item md={4} xs={12}>
          <Box
            m={fullScreen ? theme.spacing(2, 0) : 0}
            textAlign={fullScreen ? "center" : "left"}
          >
            <InfoSideBar />
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <Box>
            <Typography
              style={{ borderBottom: "1px solid", width: "max-content" }}
            >
              MERCHANT BANKING SECTION
            </Typography>
            <Typography>SEBI Registration No: INM000010619</Typography>
            <br />
            {investor?.map((data) => (
              <Typography
                style={{ marginBottom: "10px" }}
                paragraph
                color="primary"
                variant="subtitle2"
                key={data?.id}
              >
                <Link
                  href={`${documentHost}${data?.attributes?.path}`}
                  underline="always"
                >
                  {data?.attributes?.Name}
                </Link>
              </Typography>
            ))}
          </Box>

          <StockBroking />
          <ResearchSection />
        </Grid>
      </Grid>
    </>
  );
};

export default InvestorCharter;
