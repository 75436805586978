import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoSideBar from "./InfoSideBar";
import { FETCH_ACCOUNTOPENINGFROM_SUCCESS } from "./actionTypes";
import { fetchAccountOpeningForm } from "./api";

const documentHost =
  window.documentHost || process.env.REACT_APP_DOCUMENTS_HOST || "";

const useStyles = makeStyles((theme) => ({
  nestList: {
    counterReset: "item",
    display: "inline-block",
  },
  nestListItem: {
    "alignItems": "baseline",
    "&::before": {
      color: theme.palette.primary.main,
      counterIncrement: "item",
      display: "inline-block",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      marginLeft: "-1em",
      width: "1.5em",
    },
  },
  main: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));
const AccountOpeningForm = () => {
  const { tests } = useSelector((state) => state.article);
  const accountOpeningForm = useSelector((state) => state.accountOpeningForm);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    console.log("Fetching account opening form...");
    fetchAccountOpeningForm()
      .then((data) => {
        console.log("Received account opening form data:", data);
        dispatch({
          type: FETCH_ACCOUNTOPENINGFROM_SUCCESS,
          accountOpeningForm: data,
        });
      })
      .catch((error) => {
        console.error("Error fetching account opening form:", error);
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  console.log("Rendering account opening form...");
  console.log("Account opening form data:", accountOpeningForm);

  return (
    <>
      {isLoading ? <LinearProgress /> : null}

      <Grid
        spacing={10}
        container
        direction={fullScreen ? "column-reverse" : "row"}
      >
        <Grid item md={4} xs={12}>
          <Box
            m={fullScreen ? theme.spacing(2, 0) : 0}
            textAlign={fullScreen ? "center" : "left"}
          >
            <InfoSideBar />
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <Box>
            <Typography
              style={{ borderBottom: "1px solid", width: "max-content" }}
            >
              STOCK BROKING SECTION
            </Typography>
            <Typography>SEBI Registration No: INZ000001735</Typography>
            <Typography style={{ fontWeight: "600" }}>
              Account Opening Form:
            </Typography>
            <br />
            {accountOpeningForm?.length === 0 ? (
              <Typography>No data available</Typography>
            ) : (
              accountOpeningForm?.map((track) => {
                console.log("Rendering track:", track);
                return (
                  <Typography
                    style={{ margin: "15px" }}
                    paragraph
                    color="primary"
                    variant="subtitle2"
                    key={track?.id}
                  >
                    <Link
                      href={`${documentHost}${track?.attributes?.url}`}
                      underline="always"
                    >
                      {track?.attributes?.title}
                    </Link>
                  </Typography>
                );
              })
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountOpeningForm;
