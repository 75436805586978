import {
  CLOSE_DIALOG,
  FETCH_ACCOUNTOPENINGFROM_SUCCESS,
  FETCH_ADVISORY_INVESTORS_SUCCESS,
  FETCH_ARTICLES_SUCCESS,
  FETCH_CONTACT_INFOS_SUCCESS,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_FEELING_SUCCESS,
  FETCH_GENERAL_INFOMATIONS_SUCCESS,
  FETCH_INVESTOR_SUCCESS,
  FETCH_RESEARCH_SUCCESS,
  FETCH_STOCK_BROKING_E_VOTING_SUCCESS,
  FETCH_STOCK_SUCCESS,
  FETCH_TRACKRECORDS_SUCCESS,
  OPEN_DIALOG,
  REDIRECT_ARTICLE,
  RENDER_ACCEPT_DIALOG,
  RENDER_CERTIFY_DIALOG,
  RENDER_CONFIRM_DIALOG,
  RENDER_ERROR_DIALOG,
  RENDER_FORM_DIALOG,
  RENDER_SELECT_COUNTRY_DIALOG,
  RENDER_SELECT_RESIDE_AND_COUNTRY_DIALOG,
} from "./actionTypes";
// import countries from "./countries.json";
// import articles from "./articles.json";

const initialState = {
  action: null,
  article: {},
  articles: [],
  countries: [],
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case RENDER_ACCEPT_DIALOG:
      return {
        ...state,
        ...payload,
        action: RENDER_ACCEPT_DIALOG,
        next: REDIRECT_ARTICLE,
      };
    case RENDER_CERTIFY_DIALOG:
      return {
        ...state,
        ...payload,
        action: RENDER_CERTIFY_DIALOG,
        next: RENDER_CONFIRM_DIALOG,
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        ...payload,
        action: CLOSE_DIALOG,
        article: {},
      };
    case RENDER_CONFIRM_DIALOG:
      return {
        ...state,
        ...payload,
        action: RENDER_CONFIRM_DIALOG,
        next: RENDER_SELECT_COUNTRY_DIALOG,
      };
    case RENDER_ERROR_DIALOG:
      return {
        ...state,
        ...payload,
        action: RENDER_ERROR_DIALOG,
      };
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_ACCOUNTOPENINGFROM_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case FETCH_TRACKRECORDS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_INVESTOR_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_STOCK_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_RESEARCH_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_ADVISORY_INVESTORS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_FEELING_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_STOCK_BROKING_E_VOTING_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_GENERAL_INFOMATIONS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_CONTACT_INFOS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case RENDER_FORM_DIALOG:
      return {
        ...state,
        ...payload,
        action: RENDER_FORM_DIALOG,
        next: REDIRECT_ARTICLE,
      };
    case OPEN_DIALOG:
      return {
        ...state,
        ...payload,
        action: OPEN_DIALOG,
        next: payload.article.action || RENDER_ACCEPT_DIALOG,
      };
    case REDIRECT_ARTICLE:
      return {
        ...state,
        ...payload,
        action: REDIRECT_ARTICLE,
        content: [],
      };
    case RENDER_SELECT_COUNTRY_DIALOG:
      return {
        ...state,
        ...payload,
        action: RENDER_SELECT_COUNTRY_DIALOG,
        next: REDIRECT_ARTICLE,
      };
    case RENDER_SELECT_RESIDE_AND_COUNTRY_DIALOG:
      return {
        ...state,
        ...payload,
        action: RENDER_SELECT_RESIDE_AND_COUNTRY_DIALOG,
        next: REDIRECT_ARTICLE,
      };
    default:
      return state;
  }
};
