import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const documentHost =
  window.documentHost || process.env.REACT_APP_DOCUMENTS_HOST || "";

const useStyles = makeStyles((theme) => ({
  list: {
    counterReset: "item",
  },
  listItem: {
    flexDirection: "column",
    paddingBottom: "10px",
  },
  listItemWithBullet: {
    "alignItems": "baseline",
    "&::before": {
      color: theme.palette.primary.main,
      content: "counter(item) '.'",
      counterIncrement: "item",
      display: "inline-block",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      marginLeft: "-1em",
      width: "1.5em",
    },
  },
  main: {
    margin: theme.spacing(2, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));

const IndiaDocuments = () => {
  const classes = useStyles();
  const history = useHistory();

  const { attributes = {}, documents = [] } = useSelector(
    (state) => state?.article
  );

  if (Object.keys(attributes).length === 0) {
    history.push("/");
  }

  return (
    <Container
      className={classes.main}
      component="main"
      style={{
        height: "55vh",
        alignContent: "center",
        display: "flex",
        flexWrap: "wrap",
        lineHeight: "4",
      }}
    >
      <List className={classes.list} component="ol">
        {attributes.title && (
          <Box>
            <Typography
              gutterBottom
              color="primary.main"
              component="h2"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {attributes.title}
            </Typography>
          </Box>
        )}
        {attributes.message && (
          <Box>
            <Typography gutterBottom color="primary.main" component="subtitle1">
              {attributes.message}
            </Typography>
          </Box>
        )}
        {attributes.documents.length == 0 && (
          <React.Fragment>
            {attributes.role === "button" ? (
              <Box alignSelf="center">
                <Typography gutterBottom color="primary" variant="body2">
                  <Button
                    color="primary"
                    href={`${documentHost}${attributes.path}`}
                    target="_blank"
                    variant="contained"
                    style={{ marginBottom: "20px" }}
                  >
                    {attributes.label + attributes.title || attributes.path}
                  </Button>
                </Typography>
              </Box>
            ) : (
              <Typography gutterBottom color="primary" variant="body2">
                <Link
                  href={`${documentHost}${attributes.path}`}
                  target="_blank"
                  underline="always"
                  style={{ marginBottom: "20px" }}
                >
                  {attributes.title || attributes.path}
                </Link>
              </Typography>
            )}
          </React.Fragment>
        )}
        {attributes.documents.map((document) => (
          <React.Fragment key={document.id}>
            {attributes.role === "button" ? (
              <Box alignSelf="center">
                <Typography gutterBottom color="primary" variant="body2">
                  <Button
                    color="primary"
                    href={`${documentHost}${document.path}`}
                    target="_blank"
                    variant="contained"
                    style={{ marginBottom: "20px" }}
                  >
                    {attributes.label + document.title || document.path}
                  </Button>
                </Typography>
              </Box>
            ) : (
              <Typography gutterBottom color="primary" variant="body2">
                <Link
                  href={`${documentHost}${document.path}`}
                  target="_blank"
                  underline="always"
                  style={{ marginBottom: "20px" }}
                >
                  {document.title || document.path}
                </Link>
              </Typography>
            )}
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
};

export default IndiaDocuments;
