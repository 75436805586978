import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";

const ClientBankAccounts = () => {
  return (
    <>
      <div style={{ marginBottom: "30px" }}>
        <Typography
          color="primary"
          variant="subtitle2"
          style={{ marginTop: "10px" }}
        >
          <Box fontWeight="fontWeightBold">NSE</Box>
        </Typography>
        <Typography color="primary" variant="subtitle2">
          Name of Bank: Hongkong & Shanghai Banking Corporation
        </Typography>
        <Typography color="primary" variant="subtitle2">
          Name of Bank Account: CLSA India Pvt Ltd - USCNBA
        </Typography>
        <Typography color="primary" variant="subtitle2">
          Bank Account number: 002528453003
        </Typography>
        <Typography color="primary" variant="subtitle2">
          IFSC: HSBC0400002
        </Typography>
      </div>

      <div style={{ marginBottom: "40px" }}>
        <Typography color="primary" variant="subtitle2">
          <Box fontWeight="fontWeightBold">BSE</Box>
        </Typography>
        <Typography color="primary" variant="subtitle2">
          Name of Bank: Hongkong & Shanghai Banking Corporation
        </Typography>
        <Typography color="primary" variant="subtitle2">
          Name of Bank Account: CLSA India Pvt Ltd - USCNBA
        </Typography>
        <Typography color="primary" variant="subtitle2">
          Bank Account number: 002528453002
        </Typography>
        <Typography color="primary" variant="subtitle2">
          IFSC: HSBC0400002
        </Typography>
      </div>

      <Typography color="primary" variant="subtitle2">
        Investors are requested to note that CLSA India Pvt Ltd is permitted to
        receive/pay money from/to investor through designated banks accounts
        only named as client bank accounts. CLSA India Pvt Ltd is also required
        to disclose these client bank accounts to Stock Exchange. Hence, you are
        requested to use above client bank accounts only for the purpose of
        dealings in your trading account with us. The details of these client
        bank accounts are also displayed by Stock Exchanges on their website
        under "Know/ Locate your Stock Broker".
      </Typography>
    </>
  );
};

export default ClientBankAccounts;
