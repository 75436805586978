import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_CONTACT_INFOS_SUCCESS } from "./actionTypes";
import { fetchInfos } from "./api";

const documentHost =
  window.documentHost || process.env.REACT_APP_DOCUMENTS_HOST || "";

const ContactInfoSideBar = () => {
  const dispatch = useDispatch();
  const infos = useSelector((state) => state.infos);

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // fetchInfos();

    const fetchContactInfos = async () => {
      try {
        dispatch({
          type: FETCH_CONTACT_INFOS_SUCCESS,
          infos: await fetchInfos(),
        });
      } catch ({ message }) {
        setError(message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchContactInfos();
  }, [dispatch]);
  return (
    <Box
      sx={{
        backgroundColor: "rgb(234 240 254 / 72%)",
        padding: 20,
        borderRadius: 6,
        color: "#000000",
      }}
    >
      {infos?.map((d) => (
        <>
          <Typography color="primary" variant="subtitle2">
            <Box fontWeight="fontWeightBold">Registered Office</Box>
          </Typography>
          <Typography
            paragraph
            color="primary"
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: `
              ${d?.attributes?.registeredOffice}<br />`,
            }}
          />
          <Box fontWeight="fontWeightBold">
            <Link
              href={`${documentHost}${d?.attributes?.url}`}
              underline="always"
            >
              Contact us
            </Link>
          </Box>
        </>
      ))}
    </Box>
  );
};

export default ContactInfoSideBar;
