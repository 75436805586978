import { createTheme } from "@material-ui/core/styles";
import InterBold from "./fonts/Inter-Bold.ttf";
import InterMedium from "./fonts/Inter-Medium.ttf";
import InterRegular from "./fonts/Inter-Regular.ttf";
import InterSemiBold from "./fonts/Inter-SemiBold.ttf";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000066",
    },
    text: {
      primary: "#000066",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: `local('Inter'), local('Inter-Regular'), url(${InterRegular}) format('truetype')`,
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
          },
          {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 500,
            src: `local('Inter'), local('Inter-Medium'), url(${InterMedium}) format('truetype')`,
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
          },
          {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 600,
            src: `local('Inter'), local('Inter-SemiBold'), url(${InterSemiBold}) format('truetype')`,
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
          },
          {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 700,
            src: `local('Inter'), local('Inter-Bold'), url(${InterBold}) format('truetype')`,
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
          },
        ],
      },
    },
  },
});

export default theme;
