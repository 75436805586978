import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_STOCK_SUCCESS } from "./actionTypes";
import { fetchStockBroking } from "./api";

const documentHost =
  window.documentHost || process.env.REACT_APP_DOCUMENTS_HOST || "";

const StockBroking = () => {
  const StockBroking = useSelector((state) => state.StockBroking);
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchStockBroking();
    const fetchStock = async () => {
      try {
        dispatch({
          type: FETCH_STOCK_SUCCESS,
          StockBroking: await fetchStockBroking(),
        });
      } catch ({ message }) {
        setError(message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStock();
  }, [dispatch]);

  return (
    <Box style={{ margin: "20px 0px" }}>
      <Typography style={{ borderBottom: "1px solid", width: "max-content" }}>
        STOCK BROKING SECTION
      </Typography>
      <Typography>SEBI Registration No: INZ000001735</Typography>
      <br />
      {StockBroking?.map((data) => (
        <Typography
          style={{ marginBottom: "10px" }}
          paragraph
          color="primary"
          variant="subtitle2"
          key={data?.id}
        >
          <Link
            href={`${documentHost}${data?.attributes?.url}`}
            underline="always"
          >
            {data?.attributes?.title}
          </Link>
        </Typography>
      ))}
    </Box>
  );
};

export default StockBroking;
