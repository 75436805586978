import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import _includes from "lodash-es/includes";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CountrySelect from "./CountrySelect";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
  },
}));

const AccessForm = (props) => {
  const { onClose, onFail, onSuccess } = props;

  const classes = useStyles();

  const article = useSelector((state) => state.article);

  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [pincode, setPinCode] = useState("");

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        if (_includes(article.excludeCountries, country)) {
          onFail();
        } else {
          onSuccess({ country, email, name, pincode });
        }
      }}
    >
      <FormGroup>
        <TextField
          autoFocus
          fullWidth
          required
          id="name"
          label="Name"
          margin="normal"
          name="name"
          onChange={({ target: { value } }) => {
            setName(value);
          }}
        />
        <CountrySelect
          helperText="In which country to you reside? *"
          margin="normal"
          onChange={({ target: { value: country } }) => {
            setCountry(country);
          }}
        />
        <TextField
          fullWidth
          required
          autoComplete="email"
          id="email"
          label="Email Address"
          margin="normal"
          name="email"
          onChange={({ target: { value } }) => {
            setEmail(value);
          }}
        />
        <TextField
          fullWidth
          required
          id="pincode"
          label="Pin Code"
          margin="normal"
          name="pincode"
          onChange={({ target: { value } }) => {
            setPinCode(value);
          }}
        />
      </FormGroup>
      <Box display="flex" justifyContent="space-around">
        <Button fullWidth color="primary" type="submit">
          Submit
        </Button>
        <Button fullWidth color="primary" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </form>
  );
};

export default AccessForm;
