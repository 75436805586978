import get from "axios";
const apiHost = window.apiHost || process.env.REACT_APP_API_URL || "";

export const fetchArticles = async () => {
  const { data } = await get(
    `${apiHost}/api/articles?pagination[limit]=1000&sort[0]=rank:asc&populate=*`
  );
  return data.data;
};
export const fetchInfos = async () => {
  const { data } = await get(`${apiHost}/api/infos`);
  return data.data;
};

export const fetchCountries = async () => {
  const { data } = await get(
    `/countries.json?timestamp=${new Date().getTime()}`
  );
  return data;
};

export const fetchTrackRecords = async () => {
  const { data } = await get(
    `${apiHost}/api/trackrecords?pagination[limit]=1000&sort[0]=rank:asc`
  );
  return data;
};
// export const fetchgeneralInformations = async () => {
//   const { data } = await get(`${apiHost}/api/general-informations`);
//   return data.data;
// };
// export const fetchFeelingOfComplaints = async () => {
//   const { data } = await get(`${apiHost}/api/Filing-of-complaints`);
//   return data.data;
// };
export const fetchAdvisoryForInvestors = async () => {
  const { data } = await get(`${apiHost}/api/advisory-for-investors`);
  return data.data;
};
export const fetchInvestorcharters = async () => {
  const { data } = await get(
    `${apiHost}/api/investorcharters?pagination[limit]=1000&sort[0]=rank:asc`
  );
  return data.data;
};
export const fetchStockBroking = async () => {
  const { data } = await get(
    `${apiHost}/api/stock-brockings?pagination[limit]=1000&sort[0]=rank:asc`
  );
  return data.data;
};
export const fetchResearch = async () => {
  const { data } = await get(
    `${apiHost}/api/researches?pagination[limit]=1000&sort[0]=rank:asc`
  );
  return data.data;
};
export const fetchStockBrokingEvotings = async () => {
  const { data } = await get(`${apiHost}/api/stock-broking-e-votings`);
  return data.data;
};

export const fetchRiskDisclosures = async () => {
  const { data } = await get(`${apiHost}/api/risk-disclosures`);
  return data.data;
};

export const fetchAdvisoryInvestors = async () => {
  const { data } = await get(`${apiHost}/api/advisory-investors`);
  return data.data;
};

export const fetchAccountOpeningForm = async () => {
  const { data } = await get(
    `${apiHost}/api/account-opening-forms?sort[0]=id:asc`
  );
  return data.data;
};
